import * as React from "react";
import { Link } from "react-router-dom";
import { TrackingObject } from "../store/types";

import "./tracking-object-details-title.scss";
import { Organisation } from "../../organisation/domain/types";

interface TrackingObjectFormProps {
    trackingObj: TrackingObject;
    orgShortName: string;
    organisation: Organisation;
}

const TrackingObjectDetailsTitle: React.FC<TrackingObjectFormProps> = ({
    trackingObj,
    orgShortName,
    organisation,
}) => {
    return (
        <div className="tracking-object-details-title">
            <div className="title">
                <h2>
                    {organisation?.features?.trackingObjects?.displayName ??
                        "Tracking object"}{" "}
                </h2>
                <h1>{trackingObj.name}</h1>
            </div>
            <Link
                className="btn btn-primary"
                to={`/${orgShortName}/process/${trackingObj.latestProcessId}/activity/${trackingObj.latestProcessActivityId}`}
            >
                Go to {organisation?.features?.process?.createName ?? "Process"}
            </Link>
        </div>
    );
};

export default TrackingObjectDetailsTitle;
