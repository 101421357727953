import {
    HubConnection,
    HubConnectionBuilder,
    IHttpConnectionOptions,
} from "@microsoft/signalr";
import React, { useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import authService from "../../../modules/authorization/services/AuthService";
import commercialQueryKeys from "../../../modules/cefa/commercial-report/queries/commercialQueryKeys";
import { ApprovedExamsKey } from "../../../modules/cefa/exam-tools/approved-exams/queries/approvedExamsQueryKeys";
import { missingCarrsExamsKey } from "../../../modules/cefa/exam-tools/missing-carrs-exam/queries/missingCarrsExamQueryKeys";
import examinationQueryKeys from "../../../modules/cefa/examinations/query/examinationQueryKeys";
import processQueryKeys from "../../../modules/process/queries/processQueryKeys";
import userRequestedFileQueryKeys from "../../../modules/user-requested-files/queries/userRequestedFileQueryKeys";
import { Tags } from "../../../store/features/dcpApi";
import { useInvalidateTags } from "../../../store/hooks";
import {
    ArtefactChangeChangeNotification,
    ExaminationScheduleStatusChangedNotification,
    OrganisationStateChangeNotification,
    ProcessChangeNotification,
    TrackingObjectChangeNotification,
    UserRequestedFileChangeNotification,
} from "../../types/Notifications";

export const NotificationsConnection: React.FC = (): JSX.Element => {
    const connection = useRef<null | HubConnection>(null);
    const { invalidateTags } = useInvalidateTags();
    const queryClient = useQueryClient();

    useEffect(() => {
        const options: IHttpConnectionOptions = {
            accessTokenFactory: () => authService.getAccessToken(),
            withCredentials: true,
        };
        const connect = new HubConnectionBuilder()
            .withUrl("/hubs/notifications", options)
            .withAutomaticReconnect()
            .build();

        connection.current = connect;
    }, []);

    const broadcast = React.useCallback(
        (currentConnection: HubConnection) =>
            currentConnection
                .start()
                .then(() => {
                    currentConnection.on(
                        "BroadcastProcessChange",
                        (message: ProcessChangeNotification) => {
                            invalidateTags([
                                {
                                    type: Tags.ProcessActivitiesForProcess,
                                    id: message.id,
                                },
                                {
                                    type: Tags.Process,
                                    id: message.id,
                                },
                                {
                                    type: Tags.ProcessList,
                                },
                                {
                                    type: Tags.BatchExams,
                                },
                            ]);

                            queryClient.invalidateQueries(ApprovedExamsKey);

                            queryClient.invalidateQueries(missingCarrsExamsKey);

                            queryClient.invalidateQueries(
                                processQueryKeys.all(),
                            );
                        },
                    );
                    currentConnection.on(
                        "BroadcastArtefactChange",
                        (message: ArtefactChangeChangeNotification) => {
                            invalidateTags([
                                {
                                    type: Tags.ProcessActivity,
                                    id: message.processActivityId,
                                },
                            ]);
                        },
                    );
                    currentConnection.on(
                        "BroadcastTrackingObjectChange",
                        (message: TrackingObjectChangeNotification) => {
                            invalidateTags([
                                ...(message.processId
                                    ? [
                                          {
                                              type: Tags.ProcessActivitiesForProcess,
                                              id: message.processId,
                                          },
                                          message.processId && {
                                              type: Tags.ProcessList,
                                          },
                                      ]
                                    : []),
                                ...(message.assetIds
                                    ? message.assetIds.map((assetId) => ({
                                          type: Tags.Asset,
                                          id: assetId,
                                      }))
                                    : []),
                                ...(message.trackingObjectIds
                                    ? message.trackingObjectIds.map(
                                          (objId) => ({
                                              type: Tags.TrackingObject,
                                              id: objId,
                                          }),
                                      )
                                    : []),
                            ]);
                        },
                    );
                    currentConnection.on(
                        "BroadcastWorkbankStateChange",
                        (message: OrganisationStateChangeNotification) => {
                            invalidateTags([
                                {
                                    type: Tags.WorkbankUpload,
                                    id: message.organisationId,
                                },
                            ]);
                        },
                    );
                    currentConnection.on(
                        "BroadcastUserRequestedFileChange",
                        (message: UserRequestedFileChangeNotification) => {
                            console.log(
                                "BroadcastUserRequestedFileChange ",
                                JSON.stringify(message),
                            );

                            queryClient.invalidateQueries(
                                userRequestedFileQueryKeys.userRequestedFileKey(
                                    message.userRequestedFileId,
                                ),
                            );
                        },
                    );
                    currentConnection.on(
                        "BroadcastExaminationScheduleStatusChange",
                        (
                            message: ExaminationScheduleStatusChangedNotification,
                        ) => {
                            queryClient.invalidateQueries(
                                examinationQueryKeys.allExaminations(),
                            );
                        },
                    );
                    currentConnection.on(
                        "BroadcastExaminationBulkStateChange",
                        (message: OrganisationStateChangeNotification) => {
                            queryClient.invalidateQueries(
                                examinationQueryKeys.examinationsBulkUpdates(
                                    message.organisationId,
                                ),
                            );
                        },
                    );
                    currentConnection.on(
                        "BroadcastExaminationMassRemovalStateChange",
                        (message: OrganisationStateChangeNotification) => {
                            queryClient.invalidateQueries(
                                examinationQueryKeys.examinationMassRemovals(
                                    message.organisationId,
                                ),
                            );
                        },
                    );
                    currentConnection.on(
                        "BroadcastCommercialMassUpdateStateChange",
                        (message: OrganisationStateChangeNotification) => {
                            queryClient.invalidateQueries(
                                commercialQueryKeys.commercialMassUpdates(
                                    message.organisationId,
                                ),
                            );
                        },
                    );
                })
                .catch((error) => console.log(error)),
        [invalidateTags, queryClient],
    );

    useEffect(() => {
        if (connection.current) {
            broadcast(connection.current);
        }
    }, [broadcast, connection]);

    return null;
};
