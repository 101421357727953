import { push } from "connected-react-router";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LinkRow from "../../../common/components/link-row";
import { TrackingObjectListItem } from "../../trackingObject/store/types";
import "./tracking-objects-list.scss";
import { Organisation } from "../../organisation/domain/types";

type Props = {
    trackingObjects: TrackingObjectListItem[];
    organisation: Organisation;
};

const headersMapping = {
    defect: "Defects",
    trackingDefect: "Defects",
    recommendation: "Recommendations",
};

const AssetTrackingObjectList: React.FC<Props> = ({
    trackingObjects,
    organisation,
}) => {
    const assetHasTrackingObjects =
        trackingObjects?.length > 0 &&
        trackingObjects.some((item) => item.trackingObjectList?.length > 0);

    const dispatch = useDispatch();

    if (!assetHasTrackingObjects)
        return (
            <div className="mt-3">
                <Table>
                    <thead>
                        <tr>
                            <th>
                                Connected{" "}
                                {organisation?.features?.trackingObjects
                                    ?.displayName ?? "tracking objects"}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                No connected{" "}
                                {organisation?.features?.trackingObjects
                                    ?.displayName ?? "tracking objects"}
                            </th>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );

    const onNavigate = (id: string) => () => {
        dispatch(push(`/${organisation.shortName}/tracking-objects/${id}`));
    };

    return (
        <div className="mt-3">
            {trackingObjects.map(({ label, trackingObjectList }) => (
                <Table key={label} className="tracking-object-table">
                    <tbody>
                        <thead>
                            <tr>
                                <th className="tracking-object-type-title">
                                    {headersMapping[label]}
                                </th>
                            </tr>
                        </thead>
                        {trackingObjectList?.length > 0 ? (
                            trackingObjectList.map(
                                ({ id, displayName, state }) => (
                                    <LinkRow
                                        key={id}
                                        title={displayName}
                                        description={
                                            <div className="tracking-object-description">
                                                <div>{`State: ${state}`}</div>
                                                <div className="btn btn-primary">
                                                    Show details
                                                </div>
                                            </div>
                                        }
                                        onNavigate={onNavigate(id)}
                                    />
                                ),
                            )
                        ) : (
                            <tr>
                                <th>
                                    No{" "}
                                    {organisation?.features?.trackingObjects
                                        ?.displayName ?? "tracked objects"}
                                </th>
                            </tr>
                        )}
                    </tbody>
                </Table>
            ))}
        </div>
    );
};

export default AssetTrackingObjectList;
